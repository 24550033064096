import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import DefaultLayout from './default'
import bp from '../common/breakpoints'
import { motion } from 'framer-motion'

const PaperContainer = styled.div`
  position: relative;
  width: 90%;
  height: 100%;
  min-height: 400px;
  margin: 0 auto;
  margin-bottom: 2rem;
  padding-top: 5rem;
  border-radius: 20px;
  background: white;
  box-sizing: border-box;

  &::before {
    content: '';
    position: absolute;
    top: 20px;
    width: 100%;
    height: 3px;
    background-color: #ea8d22;
  }

  ${bp[3]} {
    width: 100%;
    max-width: 1320px;
  }
`

const ContentContainer = styled.div`
  width: 100%;
`

const Content = styled.div`
  padding: 75vh 0 2rem;
  @media (max-width: 812px) {
    padding: 95vh 0 2rem;
  }

  ${bp[0]} {
    padding: 85vh 0 2rem;
  }

  ${bp[2]} {
    padding: 94vh 0 2rem;
  }

  ${bp[4]} {
    padding: 94vh 0 2rem;
  }
`

const GeneralLayout = ({ children }) => {
  return (
    <DefaultLayout>
      <ContentContainer>
        <Content>
          <PaperContainer
            as={motion.div}
            initial={{ translateY: 80, opacity: 0 }}
            animate={{ translateY: 0, opacity: 1 }}
            transition={{ ease: 'easeOut', duration: 0.8 }}
          >
            {children}
          </PaperContainer>
        </Content>
      </ContentContainer>
    </DefaultLayout>
  )
}

GeneralLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default GeneralLayout
