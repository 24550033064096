import React from 'react'
import { Global, css } from '@emotion/react'
import styled from '@emotion/styled'
import { graphql, Link } from 'gatsby'
import GeneralLayout from '../components/layouts/general'
import { HeadingContainer } from '../components/common/typography'
import {
  BLACK_COLOR_RGB,
  PRIMARY_COLOR,
  PRIMARY_COLOR_RGB,
} from '../components/common/color'
import SEO from '../components/seo'

const Content = styled.div`
  padding: 1rem 0 3rem;
  width: 90%;
  margin: 0 auto;
  box-sizing: border-box;
`

const PostDate = styled.span`
  color: ${BLACK_COLOR_RGB(0.7)};
`

const BackToCareersPageLink = styled(Link)`
  position: relative;
  font-weight: bold;
  color: ${PRIMARY_COLOR};
  text-transform: uppercase;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: -0.5rem;
    width: 56px;
    height: 4px;
    background-color: ${PRIMARY_COLOR_RGB(0.8)};
  }

  &:hover {
    &::before {
      background-color: ${BLACK_COLOR_RGB(0.6)};
    }
  }
`

const CareerView = ({ data }) => {
  const {
    career: {
      frontmatter: { title, date },
      html,
      parent: { name },
      fields: { sourceInstanceName },
    },
  } = data
  return (
    <GeneralLayout>
      <SEO title={title} canonical={`${sourceInstanceName}/${name}`} />
      <Global
        styles={css`
          .career-html {
            font-family: var(--primary-font);
            margin: 1rem 0;

            h1 {
              margin: 0.5rem 0;
            }

            h4 {
              margin: 0.25rem 0;
            }

            * {
              line-height: 1.5;
            }
          }
        `}
      />
      <Content>
        <BackToCareersPageLink to="/carrers">
          Back to Careers
        </BackToCareersPageLink>
        <HeadingContainer title={title} style={{ marginTop: '2rem' }} />
        <PostDate>
          <strong>Posted At: </strong>
          {date}
        </PostDate>
        <div
          className="career-html"
          dangerouslySetInnerHTML={{ __html: html }}
        ></div>
      </Content>
    </GeneralLayout>
  )
}

export default CareerView

export const query = graphql`
  query($careerId: String!) {
    career: markdownRemark(
      id: { eq: $careerId }
      fields: { sourceInstanceName: { eq: "careers" } }
    ) {
      fields {
        sourceInstanceName
      }
      frontmatter {
        title
        date(formatString: "MMMM Do, YYYY")
        summary
      }
      html
      id
      parent {
        ... on File {
          id
          name
        }
      }
    }
  }
`
